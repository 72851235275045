enum LICENSE_EVENT_TYPE {
  LICENSE_CREATED = "LICENSE_CREATED",
  LICENSE_ACTIVATED = "LICENSE_ACTIVATED",
  LICENSE_ASSIGNED_TO_CUSTOMER = "LICENSE_ASSIGNED_TO_CUSTOMER",
  LICENSE_ASSIGNED_TO_ORGANIZATION = "LICENSE_ASSIGNED_TO_ORGANIZATION",

  RESOURCE_ADDED_TO_LICENSE = "RESOURCE_ADDED_TO_LICENSE",
  FUNCTIONALITY_ADDED_TO_LICENSE = "FUNCTIONALITY_ADDED_TO_LICENSE",

  RESOURCE_REMOVED_FROM_LICENSE = "RESOURCE_REMOVED_FROM_LICENSE",
  FUNCTIONALITY_REMOVED_FROM_LICENSE = "FUNCTIONALITY_REMOVED_FROM_LICENSE",

  LICENSE_RESOURCE_UPDATED = "LICENSE_RESOURCE_UPDATED",
  LICENSE_FUNCTIONALITY_UPDATED = "LICENSE_FUNCTIONALITY_UPDATED",

  ASSET_SET_TO_LICENSE = "ASSET_SET_TO_LICENSE",

  LICENSE_BLOCKED = "LICENSE_BLOCKED",
  LICENSE_UNBLOCKED = "LICENSE_UNBLOCKED",

  LICENSE_EXPIRED = "LICENSE_EXPIRED",

}


export default LICENSE_EVENT_TYPE
