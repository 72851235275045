import { SelectOptions } from "$templates/components/Select"

import { IconName } from "$templates/components/Icon"
import LICENSE_EVENT_TYPE from "$types/LICENSE_EVENT_TYPE"
import LICENSE_TYPE from "$types/LICENSE_TYPE"
import { Exception } from "handlebars"
import { LicenseTemplateValues } from "$templates/views/LicenseCreateView"
import { ParsedLicenseTemplate } from "./LicenseTemplating"
import { ResourceComplete } from "$services/ResourcesService"

// IDS
export const licenseFormId = "license-form"
export const licenseResourcesInputName = "resources"
export const licenseFunctionalitiesInputName = "functionalities"
export const licenseDetailCustomerSectionId = "license-detail-customer-section"
export const licenseCustomerTransferModalId = "customer-transfer-modal"
export const licenseCustomerTransferFormId = "customer-transfer-form"
export const licenseDetailOrganizationSectionId = "license-detail-organization-section"
export const licenseOrganizationTransferModalId = "organization-transfer-modal"
export const licenseOrganizationTransferFormId = "organization-transfer-form"
export const licenseDetailBlockSectionId = "license-detail-block-section"
export const licenseBlockModalId = "license-block-modal"

// LABELS
export const licenseEventTypeLabels: {
  [type in LICENSE_EVENT_TYPE]: string
} = {
  LICENSE_CREATED: "License created",
  LICENSE_ACTIVATED: "License activated",
  LICENSE_BLOCKED: "License blocked",
  LICENSE_UNBLOCKED: "License enabled",
  LICENSE_EXPIRED: "License expired",

  LICENSE_ASSIGNED_TO_CUSTOMER: "Customer transfer",
  LICENSE_ASSIGNED_TO_ORGANIZATION: "Organization transfer",

  RESOURCE_ADDED_TO_LICENSE: "Resource added",
  RESOURCE_REMOVED_FROM_LICENSE: "Resource removed",
  LICENSE_RESOURCE_UPDATED: "Resource updated",

  FUNCTIONALITY_ADDED_TO_LICENSE: "Functionality added",
  FUNCTIONALITY_REMOVED_FROM_LICENSE: "Functionality removed",
  LICENSE_FUNCTIONALITY_UPDATED: "Functionality updated",

  ASSET_SET_TO_LICENSE: "Asset updated",
}

export const licenseEventTypeIcons: Partial<{
  [type in LICENSE_EVENT_TYPE]: {
    name: IconName
    class?: string
  }
}> = {
  LICENSE_CREATED: {
    name: "plus",
    class: "text-green-600",
  },
  LICENSE_ACTIVATED: {
    name: "plus",
    class: "text-green-600",
  },
  LICENSE_BLOCKED: {
    name: "lock",
    class: "text-red-800",
  },
  LICENSE_UNBLOCKED: {
    name: "lock-open",
    class: "text-green-700",
  },
  LICENSE_EXPIRED: {
    name: "minus",
    class: "text-red-700",
  },

  LICENSE_ASSIGNED_TO_CUSTOMER: {
    name: "chevron-double-right",
  },
  LICENSE_ASSIGNED_TO_ORGANIZATION: {
    name: "chevron-double-right",
  },

  RESOURCE_ADDED_TO_LICENSE: {
    name: "plus",
    class: "text-green-600",
  },
  RESOURCE_REMOVED_FROM_LICENSE: {
    name: "minus",
    class: "text-red-700",
  },
  LICENSE_RESOURCE_UPDATED: {
    name: "rotate",
  },

  FUNCTIONALITY_ADDED_TO_LICENSE: {
    name: "plus",
    class: "text-green-600",
  },
  FUNCTIONALITY_REMOVED_FROM_LICENSE: {
    name: "minus",
    class: "text-red-700",
  },
  LICENSE_FUNCTIONALITY_UPDATED: {
    name: "rotate",
  },

  ASSET_SET_TO_LICENSE: {
    name: "rotate",
  },
}

// VALUES
export const licenseEventTypeValues = Object.keys(LICENSE_EVENT_TYPE) as LICENSE_EVENT_TYPE[]

// Demo type and utility
export enum DEMO_DURATION {
  WEEK = "WEEK",
  FIFTEEN_DAYS = "FIFTEEN_DAYS",
  MONTH = "MONTH",
  THREE_MONTHS = "THREE_MONTHS",
  SIX_MONTHS = "SIX_MONTHS"
}

export const getDemoLabelValue = (duration: DEMO_DURATION): string => {
  switch (duration) {
    case DEMO_DURATION.WEEK:
      return "1 week"
    case DEMO_DURATION.FIFTEEN_DAYS:
      return "15 days"
    case DEMO_DURATION.MONTH:
      return "1 month"
    case DEMO_DURATION.THREE_MONTHS:
      return "3 months"
    case DEMO_DURATION.SIX_MONTHS:
      return "6 months"
  }
}

export const getDemoDurationFromDaysNumber = (duration: number): DEMO_DURATION | null => {
  switch (duration) {
    case 7:
      return DEMO_DURATION.WEEK
    case 15:
      return DEMO_DURATION.FIFTEEN_DAYS
    case 30:
      return DEMO_DURATION.MONTH
    case 90:
      return DEMO_DURATION.THREE_MONTHS
    case 180:
      return DEMO_DURATION.SIX_MONTHS
    default:
      return null
  }
}

export const getDemoDaysValue = (duration: DEMO_DURATION): number => {
  switch (duration) {
    case DEMO_DURATION.WEEK:
      return 7
    case DEMO_DURATION.FIFTEEN_DAYS:
      return 15
    case DEMO_DURATION.MONTH:
      return 30
    case DEMO_DURATION.THREE_MONTHS:
      return 90
    case DEMO_DURATION.SIX_MONTHS:
      return 180
  }
}

export const getDemoDurationSelectOptions = (selected?: string): SelectOptions => {
  return Object.keys(DEMO_DURATION).map(key => {
    const label = getDemoLabelValue(key as DEMO_DURATION)

    return {
      label,
      value: key,
      selected: key === selected
    }
  })
}

// Templates

export const getTemplateValues = (templates: ParsedLicenseTemplate[]): LicenseTemplateValues => {
  let expirationDate: string | null = null
  let duration: DEMO_DURATION | null = null
  const firstTemplate = templates[0]
  switch (firstTemplate.specificProperties.type) {
    case LICENSE_TYPE.FINAL:
      expirationDate = firstTemplate.specificProperties.expirationDate ?? null
      break
    case LICENSE_TYPE.SUBSCRIPTION:
      expirationDate = firstTemplate.specificProperties.expirationDate
      break
    case LICENSE_TYPE.NFR:
      expirationDate = firstTemplate.specificProperties.expirationDate
      break
    case LICENSE_TYPE.DEMO:
      duration = getDemoDurationFromDaysNumber(firstTemplate.specificProperties.duration)
      break
    default:
      throw new Exception("Invalid license template")
  }

  const allResources = templates.map(template => template.resources).flat()
  const uniqueResourcesSlugs = Array.from(new Set(allResources.map(resource => resource.resource.slug)))
  const resources: string = uniqueResourcesSlugs.map(slug => {
    const resource = allResources.find(r => r.resource.slug === slug) as { resource: ResourceComplete, supportedResourceVersions: string }
    return `${slug}:${resource.supportedResourceVersions}`
  }).join(",")

  const functionalities: string = Array.from(new Set(templates.map(template => template.functionalities.map(f => f.slug)).flat())).join(",")

  const licenseTemplate: LicenseTemplateValues = {
    basicInformation: {
      type: firstTemplate.specificProperties.type.toString(),
      expirationDate,
      duration,
      resources,
      functionalities
    },
    attributes: {
      supportedApplicationVersions: firstTemplate.supportedApplicationVersions,
      assetId: firstTemplate.customAsset?.id.toString() ?? "0",
      supportedAssetVersions: firstTemplate.supportedAssetVersions,
    }
  }

  return licenseTemplate
}

export const encodeToBase64URL = (string: string): string => {
  return Buffer.from(string, "binary").toString("base64url")
}

export const decodeFromBase64URL = (base64URL: string): string => {
  return Buffer.from(base64URL, "base64url").toString("binary")
}
