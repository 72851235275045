import { licenseCustomerTransferFormId, licenseCustomerTransferModalId, licenseFormId, licenseFunctionalitiesInputName, licenseResourcesInputName } from "$utils/licenses"
import htmx from "htmx.org"
import { loader } from "src/client/scripts/modules/loader"
import * as SelectSearch from "./selectSearch"
// ==================== HELPERS ==================== //

// resources
function getResourceData(value: string): [string, string] {
  const values = value.split(":")
  return [
    values[0],
    values[1] || "*"
  ]
}
function getResourcesData(value: string): [string, string][] {
  return value.split(",").filter(v => v.length).map(getResourceData)
}
function formatResourcesData(data: [string, string][]): string {
  return data.map(([id, ver]) => `${id}:${ver.trim() || "*"}`).join(",")
}

// functionalities
function getFunctionalitiesData(value: string): string[] {
  return value.split(",").filter(v => v.length).map(v => v)
}
function formatFuncionalitiesData(data: string[]): string {
  return data.map(v => `${v}`).join(",")
}

window.onLicenseResOrFunCheckEdit = (event: Event) => {
  const target = event.target as HTMLInputElement

  const name = target.name
  if (!["resources", "functionalities"].includes(name)) {
    console.error(`Invalid name: ${name}`)
    return
  }

  const checked = target.checked
  const slug = target.value
  if (!slug) {
    console.error(`Invalid slug: ${slug}`, target)
    return
  }

  const form = document.getElementById(licenseFormId)
  if (form) {
    const inputHidden = form.querySelector(`[name="${name}"]`) as HTMLInputElement
    if (inputHidden) {
      if (name === "resources") {
        const data = getResourcesData(inputHidden.value)

        if (checked) {
          const versionInput = document.getElementById(`semver-input-${slug}`) as HTMLInputElement
          if (versionInput) {
            const version = versionInput.value
            if (!version || !window.validateSemverString(version)) {
              versionInput.focus()
            } else {
              if (!data.find(([i]) => i === slug)) {
                data.push([slug, version])
              }
            }
          } else {
            console.error(`Version input not found for resource id: ${slug}`)
            target.checked = false
          }
        } else {
          const index = data.findIndex(([i]) => i === slug)
          if (index !== -1) {
            data.splice(index, 1)
          }
        }
        inputHidden.value = formatResourcesData(data)

      } else {
        const data = getFunctionalitiesData(inputHidden.value)

        if (checked) {
          if (!data.includes(slug)) {
            data.push(slug)
          }
        } else {
          const index = data.indexOf(slug)
          if (index !== -1) {
            data.splice(index, 1)
          }
        }
        inputHidden.value = formatFuncionalitiesData(data)
      }
    }
  } else {
    console.error(`Form not found: ${licenseFormId}`)
  }
}

window.onLicenseSemverInputChange = (input: HTMLInputElement) => {
  const version = input.value || "*"

  const slug = input.id.replace("semver-input-", "")
  if (!slug) {
    console.error("Resource slug not found for version input", input)
    return
  }

  const form = document.getElementById(licenseFormId)
  if (!form) {
    console.error(`Form not found: ${licenseFormId}`)
    return
  }

  const inputHidden = form.querySelector(`[name="${licenseResourcesInputName}"]`) as HTMLInputElement
  if (!inputHidden) {
    console.error("Resources hidden input not found")
    return
  }

  const checkbox = document.querySelector(`[type="checkbox"][name="${licenseResourcesInputName}"][value="${slug}"]`) as HTMLInputElement
  if (!checkbox) {
    console.error(`Resource checkbox not found for resource slug: ${slug}`)
    return
  }

  const data = getResourcesData(inputHidden.value)
  const index = data.findIndex(([i]) => i === slug)

  if (window.validateSemverString(version)) {
    if (checkbox.checked) {
      if (index !== -1) {
        data[index][1] = version
      } else {
        data.push([slug, version])
      }
      inputHidden.value = formatResourcesData(data)
    }
  } else {
    if (index !== -1) {
      data.splice(index, 1)
      inputHidden.value = formatResourcesData(data)
    }

    checkbox.checked = false
  }
}

window.fillLicenseResOrFunCheckboxes = () => {
  const form = document.getElementById(licenseFormId)
  if (!form) {
    console.error(`Form not found: ${licenseFormId}`)
    return
  }

  const resInput = form.querySelector(`[name="${licenseResourcesInputName}"]`) as HTMLInputElement
  if (resInput) {
    const resData = getResourcesData(resInput.value)
    const checkboxes = document.querySelectorAll(`[type="checkbox"][name="${licenseResourcesInputName}"]`)
    if (checkboxes) {
      checkboxes.forEach(checkbox => {
        const ck = checkbox as HTMLInputElement
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const data = resData.find(([slug, _]) => slug === ck.value)
        if (data) {
          ck.checked = true
          const versionInput = document.getElementById(`semver-input-${data[0]}`) as HTMLInputElement
          if (versionInput) {
            versionInput.value = data[1]
          }
        } else {
          ck.checked = false
          const versionInput = document.getElementById(`semver-input-${ck.value}`) as HTMLInputElement
          if (versionInput) {
            versionInput.value = ""
          }
        }
      })
    }
  } else {
    console.error("Resources hidden input not found")
  }

  const funcInput = form.querySelector(`[name="${licenseFunctionalitiesInputName}"]`) as HTMLInputElement
  if (funcInput) {
    const funcSlugs = getFunctionalitiesData(funcInput.value)
    const checkboxes = document.querySelectorAll(`[type="checkbox"][name="${licenseFunctionalitiesInputName}"]`)
    if (checkboxes) {
      checkboxes.forEach(checkbox => {
        const ck = checkbox as HTMLInputElement
        if (funcSlugs.includes(ck.value)) {
          ck.checked = true
        } else {
          ck.checked = false
        }
      })
    }
  } else {
    console.error("Functionalities hidden input not found")
  }
}

const setJSONFileError = (form: HTMLFormElement, errorMessage: string) => {
  const inputWrap = form.querySelector("[class=file-input__caption]")

  if (!inputWrap) {
    return
  }
  const fileInputWrap = inputWrap.closest(".file-input__wrap")
  if (!fileInputWrap) {
    return
  }

  if (!fileInputWrap.classList.contains("file-input__wrap--error")) {
    fileInputWrap.classList.add("file-input__wrap--error")
  }
  inputWrap.innerHTML = errorMessage
  const submitButton = document.querySelector(`[form=${form.id}]`)
  if (submitButton) {
    submitButton?.setAttribute("disabled", "")
  }
}

const clearJSONFileError = (form: HTMLFormElement) => {
  const inputWrap = form.querySelector("[class=file-input__caption]")

  if (!inputWrap) {
    return
  }
  const fileInputWrap = inputWrap.closest(".file-input__wrap")
  if (!fileInputWrap) {
    return
  }

  if (fileInputWrap.classList.contains("file-input__wrap--error")) {
    inputWrap.innerHTML = "<span class='text-xs'>File extension: JSON - MAX 100kb</span>"
    fileInputWrap.classList.remove("file-input__wrap--error")
  }
  const submitButton = document.querySelector(`[form=${form.id}]`)
  if (submitButton) {
    submitButton.removeAttribute("disabled")
  }
}

const readFileAsText = (file: Blob, parsedContent: { content: string, order: number }[], form: HTMLFormElement, order: number) => {
  return new Promise(function(resolve, reject) {
      const reader = new FileReader()

      reader.onload = (e) => {
        const content = e.target?.result as string

        try {
          parsedContent.push({ content: JSON.parse(content), order })
        } catch {
          setJSONFileError(form, "Invalid JSON")
          loader.hide()
          reject()
        }

        resolve(content)
      }

      reader.readAsText(file)
  })
}

window.licenseTemplateUploader = (event: CustomEvent, form: HTMLFormElement) => {
  // Need guard for event target, especially if called inline
  if (event.target != form) {
    return
  }

  event.preventDefault()
  const fileInputs = form.querySelectorAll("input[type=file]")
  if (!fileInputs.length) {
    loader.hide()
    return false
  }

  const inputs = form.querySelectorAll("input[type=hidden][name=licenseTemplate]")
  if (!inputs.length) {
    loader.hide()
    return false
  }

  const applicationId = event.detail.requestConfig.parameters.applicationId
  const files = Array.from(fileInputs).map(input => {
    const container = input.closest("[data-index]") as HTMLElement
    return ({
      file: (input as HTMLInputElement).files?.[0],
      order: container.getAttribute("data-index") ? parseInt(container.getAttribute("data-index") as string) : 0
    })
  })

  if (applicationId) {
    if (!files.length) {
      const context = {
        target: form.getAttribute("hx-target"),
        swap: form.getAttribute("hx-swap"),
        values: {
          applicationId: applicationId === "undefined" ? undefined : applicationId,
          licenseTemplates: [""]
        }
      }
      htmx.ajax("POST", form.action, context)
      return true
    }

    if (files.some(({ file }) => file && file.size > 100 * 1024)) {
      setJSONFileError(form, "File too large")
      loader.hide()
      return false
    }

    const parsedContent: { content: string, order: number }[] = []
    const readers = files.map(({ file, order }) => {
      if (!file) {
        return null
      }

      return readFileAsText(file, parsedContent, form, order)
    }).filter(e => !!e)

    Promise.all(readers).then(() => {
      const orderedTemplates = parsedContent.sort((a, b) => a.order - b.order).map(e => e.content)
      const context = {
        target: form.getAttribute("hx-target"),
        swap: form.getAttribute("hx-swap"),
        values: {
          applicationId: applicationId === "undefined" ? undefined : applicationId,
          licenseTemplates: orderedTemplates.length ? orderedTemplates : [""]
        }
      }
      htmx.ajax("POST", form.action, context)
    })
  } else {
    if (!files.length) {
      const context = {
        target: form.getAttribute("hx-target"),
        swap: form.getAttribute("hx-swap"),
        values: {
          licenseTemplates: [""]
        }
      }
      htmx.ajax("POST", form.action, context)
      return
    }

    const parsedContent: { content: string, order: number }[] = []
    const readers = files.map(({ file, order }) => {
      if (!file) {
        return null
      }

      return readFileAsText(file, parsedContent, form, order)
    }).filter(e => !!e)

    Promise.all(readers).then(() => {
      const orderedTemplates = parsedContent.sort((a, b) => a.order - b.order).map(e => e.content)
      const context = {
        target: form.getAttribute("hx-target"),
        swap: form.getAttribute("hx-swap"),
        values: {
          licenseTemplates: orderedTemplates.length ? orderedTemplates : [""]
        }
      }
      htmx.ajax("POST", form.action, context)
    })
  }
}

export const resetFileInput = (form: HTMLFormElement) => {
  const fileInput = form.querySelector("input[type=file]") as HTMLInputElement | null
  if (!fileInput) return

  clearJSONFileError(form)
  fileInput.value = ""
  const inputText = form.querySelector(".file-input__content-text")
  if (inputText) {
    inputText.innerHTML = "No file chosen"
  }
}

const validateJSON = (form: HTMLFormElement) => {
  const fileInput = form.querySelector("input[type=file]") as HTMLInputElement | null
  if (!fileInput) {
    return false
  }

  const file = fileInput.files?.[0]
  if (!file) {
    return false
  }

  if (file.size > 100 * 1024) {
    setJSONFileError(form, "File too large")
    return false
  }

  const reader = new FileReader()
  reader.onload = (e) => {
    const content = e.target?.result as string

    try {
      JSON.parse(content)
    } catch {
      setJSONFileError(form, "Invalid JSON")
      return
    }
    clearJSONFileError(form)
  }
  reader.readAsText(file)
}

window.downloadLicenseTemplate = async (event) => {
  const response = JSON.stringify(JSON.parse(event.detail.xhr.response), null, 4)
  const blob: Blob = new Blob([response], { type: "application/json" })
  const urlObject = URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = urlObject
  link.download = "template.json"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(urlObject)
}

const liceseSetupMutualReset = (e: HTMLFormElement) => {
  const fileInput = e.querySelector("#license-template-json")
  const selectSearch: HTMLDivElement | null = e.querySelector(".select-search-wrap")

  if (!fileInput || !selectSearch) return

  const searchInput: HTMLInputElement | null = selectSearch.querySelector("[class*=select-search-input]")
  if (!searchInput) return

  fileInput.addEventListener("input", () => {
    SelectSearch.resetSelectSearch(selectSearch)
  })

  searchInput.addEventListener("input", () => {
    resetFileInput(e)
  })
}

export const initLicenseSetupForm = (e: Element) => {
  if (e.id === "license-setup-form" && e instanceof HTMLFormElement) {
    e.addEventListener("change", () => {
      validateJSON(e)
    })

    liceseSetupMutualReset(e)
    return
  }

  const licenseSetupForm: NodeListOf<HTMLFormElement> = e.querySelectorAll("#license-setup-form")
  if (licenseSetupForm.length === 1) {

    licenseSetupForm.forEach(e => {
      e.addEventListener("change", () => {
        validateJSON(e)
      })

      liceseSetupMutualReset(e)
    })
  } else if (licenseSetupForm.length > 1) {
    console.error("Non unique license setup form id")
  }
}


export const initLicenseImportForm = (e: Element) => {
  if (e.id === "license-import-template-form" && e instanceof HTMLFormElement) {
    e.addEventListener("change", () => {
      validateJSON(e)
    })

    return
  }

  const licenseSetupForm: NodeListOf<HTMLFormElement> = e.querySelectorAll("#license-import-template-form")
  if (licenseSetupForm.length === 1) {

    licenseSetupForm.forEach(e => {
      e.addEventListener("change", () => {
        validateJSON(e)
      })
    })
  } else if (licenseSetupForm.length > 1) {
    console.error("Non unique license import template form id")
  }
}

const liceseCustomerTransferMutualReset = (e: HTMLDivElement) => {
  const modalForm = e.querySelector("#" + licenseCustomerTransferFormId)
  const modalFooter = e.querySelector(".modal__footer")

  if (!modalForm || !modalFooter) return

  const mailInput: HTMLInputElement | null = modalForm.querySelector("input[type='email'][name='customerEmail']")
  const selectSearch: HTMLDivElement | null = modalForm.querySelector(".select-search-wrap")
  const modalControlButtons = modalFooter.querySelectorAll("button")

  if (!mailInput || !selectSearch || !modalControlButtons) return

  const searchInput: HTMLInputElement | null = selectSearch.querySelector("[class*=select-search-input]")
  if (!searchInput) return


  mailInput.addEventListener("input", () => {
    SelectSearch.resetSelectSearch(selectSearch)

    modalControlButtons.forEach(e => {
      e.removeAttribute("disabled")
    })
  })

  searchInput.addEventListener("input", () => {
    mailInput.value = ""

    modalControlButtons.forEach(e => {
      e.removeAttribute("disabled")
    })
  })
}

// License customer transfer modal form

export const initLicenseCustomerTransferModal = (e: Element) => {
  if (e.id === licenseCustomerTransferModalId && e instanceof HTMLDivElement) {
    liceseCustomerTransferMutualReset(e)

    return
  }


  const licenseCustomerTransferModal: NodeListOf<HTMLDivElement> = e.querySelectorAll("#" + licenseCustomerTransferModalId)
  if (licenseCustomerTransferModal.length === 1) {

    licenseCustomerTransferModal.forEach(e => {
      liceseCustomerTransferMutualReset(e)
    })

  } else if (licenseCustomerTransferModal.length > 1) {
    console.error("Non unique license customer transfer modal id")
  }
}
