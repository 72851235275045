import htmx from "htmx.org"

const searchInputEditedAttribute = "item-edited"
const searchInputSelectedAttribute = "item-selected"

export const htmxInit = (e: Element) => {
  // When a new select search is swapped, its listeners get initialized
  if (e.classList.contains("select-search-wrap")) {
    initSelectSearch(e as HTMLDivElement)
    return
  }

  // When an element with one or more select searches is swapped, its listeners get initialized
  const selectSearches: NodeListOf<HTMLDivElement> = e.querySelectorAll(".select-search-wrap")

  if (selectSearches.length > 0) {
    // Select search event listeners
    selectSearches.forEach(e => {
      initSelectSearch(e)
    })
  }

  // When new dropdown items are swapped, its listeners get initialized
  // If more are swapped with oob swap for some reason, they should still be processed as onLoad triggers
  // for each individual element swapped. SelectSearchableDropdownItems has to be swapped and nothing else in items endpoint
  if (e.classList.contains("dropdown__items")) {
    initDropdownItems(e as HTMLUListElement)
  }
}

const initSelectSearch = (e: HTMLDivElement) => {
  // Get select search components
  const searchInput: HTMLInputElement | null = e.querySelector("[class*=select-search-input]")
  const hiddenInput: HTMLInputElement | null = e.querySelector("input[type=hidden]")
  const selectSearchableDropdown: HTMLDivElement | null = e.querySelector("[class*=select-search-dropdown]")

  if (!searchInput || !hiddenInput || !selectSearchableDropdown) return

  initLiItems(selectSearchableDropdown, searchInput, hiddenInput)

  searchInput.addEventListener("click", e => {
    e.stopPropagation()
  })

  searchInput.addEventListener("focus", () => {
    window.openDropdown(selectSearchableDropdown.id)
  })

  searchInput.addEventListener("input", e => {
    searchInput.setAttribute(searchInputEditedAttribute, "true")

    e.stopPropagation()
  })

  // Reset search value if it has been edited by user
  searchInput.addEventListener("blur", () => {
    if (searchInput.hasAttribute(searchInputEditedAttribute) && !searchInput.hasAttribute(searchInputSelectedAttribute)) {

      searchInput.value = ""
      hiddenInput.value = hiddenInput.dataset.emptyValue ?? ""
      // dispatch change event
      searchInput?.dispatchEvent(new Event("input"))
      hiddenInput?.dispatchEvent(new Event("input"))
      htmx.trigger(searchInput, "changed", {})
    }
    searchInput.removeAttribute(searchInputEditedAttribute)
    searchInput.removeAttribute(searchInputSelectedAttribute)
  })
}

// Init dropdown items on swap after a search
const initDropdownItems = (e: HTMLUListElement) => {
  const selectContainer: HTMLInputElement | null = e.closest(".select__container")
  if (!selectContainer) return

  const searchInput: HTMLInputElement | null = selectContainer.querySelector("[class*=select-search-input]")
  const hiddenInput: HTMLInputElement | null = selectContainer.querySelector("input[type=hidden")
  const selectSearchableDropdown: HTMLDivElement | null = selectContainer.querySelector("[class*=select-search-dropdown]")

  if (!searchInput || !hiddenInput || !selectSearchableDropdown) return

  initLiItems(selectSearchableDropdown, searchInput, hiddenInput)

  window.onDropdownResize()
}

// Adds htmx on click call functionality to list items. Highlights selected item
const initLiItems = (
  selectSearchableDropdown: HTMLDivElement,
  searchInput: HTMLInputElement,
  hiddenInput: HTMLInputElement
) => {
  const dropdownListItems = selectSearchableDropdown.querySelectorAll("li")

  const {
    onSelectGetAction,
    onSelectPostAction,
    onSelectActionInclude,
    onSelectActionTarget,
    onSelectActionSwap
  } = searchInput.dataset

  if (onSelectActionInclude) {
    selectSearchableDropdown.setAttribute("hx-include", onSelectActionInclude)
  }

  if (onSelectActionTarget) {
    selectSearchableDropdown.setAttribute("hx-target", onSelectActionTarget)
  }

  if (onSelectActionSwap) {
    selectSearchableDropdown.setAttribute("hx-swap", onSelectActionSwap)
  }

  dropdownListItems.forEach((e: HTMLLIElement) => {
    if (onSelectGetAction || onSelectPostAction) {
      const actionType = onSelectGetAction ? "hx-get" : "hx-post"
      const actionValue = onSelectGetAction || onSelectPostAction!

      e.setAttribute(actionType, actionValue)
      e.setAttribute("hx-trigger", "mousedown")
    }

    // Has to be mousedown to be called before onBlur. Selected value is always zero otherwise
    e.addEventListener("mousedown", e => {
      if (e.currentTarget instanceof HTMLLIElement) {
        dropdownListItems.forEach(item => {
          item.classList.remove("dropdown__item--selected")
        })

        const selectedItem = e.currentTarget

        selectedItem.classList.add("dropdown__item--selected")
        if (selectedItem.dataset.value) hiddenInput.value = selectedItem.dataset.value
        if (selectedItem.dataset.label) searchInput.value = selectedItem.dataset.label

        // dispatch change event
        searchInput?.dispatchEvent(new Event("input"))
        hiddenInput?.dispatchEvent(new Event("input"))

        searchInput.setAttribute(searchInputSelectedAttribute, "true")

        window.closeDropdown(selectSearchableDropdown.id)
        window.clearInputError(searchInput)
      }
    })
  })

  htmx.process(selectSearchableDropdown)
}

// loading animation handlers. Legacy. Can be done with hx-indicator with animated svg icon
export const onSelectSearchBeforeRequest = (event: Event) => {
  const searchInput = event.target as HTMLInputElement
  if (!searchInput || !searchInput.classList.contains("select-search-input")) return
  const container = searchInput.closest(".select-search-wrap")
  if (!container) return

  const iconTag = container.querySelector(".select__icon use")
  if (iconTag) {
    iconTag.closest("div")?.classList.add("animation-spin")
    iconTag.setAttribute("href", "#spinner")
  }
}

export const onSelectSearchAfterRequest = (event: Event) => {
  const searchInput = event.target as HTMLInputElement
  if (!searchInput || !searchInput.classList.contains("select-search-input")) return
  const container = searchInput.closest(".select-search-wrap")

  const iconTag = container?.querySelector(".select__icon use")
  if (iconTag) {
    iconTag.closest("div")?.classList.remove("animation-spin")
    iconTag.setAttribute("href", "#angle-down")
  }
}

// For reset by external flows
export const resetSelectSearch = (selectSearch: HTMLDivElement) => {
  const searchInput: HTMLInputElement | null = selectSearch.querySelector("[class*=select-search-input]")
  const hiddenInput: HTMLInputElement | null = selectSearch.querySelector("input[type=hidden]")
  if (!searchInput || !hiddenInput || hiddenInput.value === hiddenInput.dataset.emptyValue) return

  searchInput.value = ""
  hiddenInput.value = hiddenInput.dataset.emptyValue ?? ""
  htmx.trigger(searchInput, "changed", {})
  searchInput.removeAttribute(searchInputEditedAttribute)
  searchInput.removeAttribute(searchInputSelectedAttribute)
}
