import htmx from "htmx.org"
import axios from "axios"

export type AssetVersionFileUploader = {
  uploadFile: (event: SubmitEvent, form: HTMLFormElement, assetId: number) => Promise<boolean>
}

const handleButtons = (form: HTMLFormElement, action: "enable" | "disable") => {
  const formFooter = document.getElementById(`${form.id}-footer`)
  if (!formFooter) {
    return
  }

  if (action === "disable") {
    form.closest(".modal")?.classList.add("pointer-events-none")
    const cancelButton = formFooter.querySelector("button[type=button]")
    if (cancelButton) {
      cancelButton.setAttribute("disabled", "disabled")
    }
    const submitButton = formFooter.querySelector("button[type=submit]")
    if (submitButton) {
      submitButton.classList.add("btn--loading")
    }
  } else {
    form.closest(".modal")?.classList.remove("pointer-events-none")
    const cancelButton = formFooter.querySelector("button[type=button]")
    if (cancelButton) {
      cancelButton.removeAttribute("disabled")
    }
    const submitButton = formFooter.querySelector("button[type=submit]")
    if (submitButton) {
      submitButton.classList.remove("btn--loading")
    }
  }
}

const submit = async(form: HTMLFormElement) => {
  const url = form.getAttribute("action")
  if (!url) {
    return
  }

  const targetAssetId = (form.querySelector("input[name=targetAssetId]") as HTMLInputElement | undefined)?.value

  const values = {
    ...(targetAssetId ? { targetAssetId } : {
      name: (form.querySelector("input[name=name]") as HTMLInputElement).value,
      targetApplicationId: (form.querySelector("input[name=targetApplicationId]") as HTMLInputElement).value
    }),
    version: (form.querySelector("input[name=version]") as HTMLInputElement).value,
    installer: (form.querySelector("input[name=installer]") as HTMLInputElement).value,
    description: (form.querySelector("textarea[name=description]") as HTMLTextAreaElement).value
  }

  await htmx.ajax("POST", url, {
    values,
    target: `#${form.id}`,
    swap: "outerHTML",
  })

  handleButtons(form, "enable")
}

const handleError = async (form: HTMLFormElement, message?: string) => {
  const errorEndpoint = form.getAttribute("data-error-endpoint")

  if (!errorEndpoint) {
    handleButtons(form, "enable")
    return
  }

  const errorMessage = message ?? "Failed to upload file"

  await htmx.ajax("GET", `${errorEndpoint}?error=${errorMessage}`, {
    target: `#${form.id}`,
    swap: "beforebegin"
  })

  handleButtons(form, "enable")
}

const uploadFile = async (event: SubmitEvent, form: HTMLFormElement, assetId: number): Promise<boolean> => {
  const input = form.querySelector("input[type=file]") as HTMLInputElement | null
  if (!input) {
    event.preventDefault()
    return false
  }

  const file = input.files?.[0]
  if (!file) {
    event.preventDefault()
    return false
  }

  const nameInput = form.querySelector("input[name=name]") as HTMLInputElement | null
  const name = nameInput?.value ?? ""

  const payload: {
    targetAssetId?: number
    version: string
  } = {
    ...(assetId ? { targetAssetId: assetId } : { name }),
    version: "",
  }

  const versionInput = form.querySelector("input[name=version]") as HTMLInputElement | null
  if (versionInput) {
    payload.version = versionInput.value
  } else {
    event.preventDefault()
    return false
  }

  const endpoint = form.getAttribute("data-presigned-url-endpoint")
  if (!endpoint) {
    event.preventDefault()
    return false
  }

  handleButtons(form, "disable")

  try {
    const data = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    })

    const res = await data.json()
    const {
      url, partialKey
    } = res

    const footer = document.getElementById(`${form.id}-footer`)
    const button = footer?.querySelector("button[type=submit]")
    const textContainer = button?.querySelector("#btn--text")
    const originalText = textContainer?.innerHTML ?? ""
    let shouldUpdateText = false
    const timeoutId = setTimeout(() => {
      shouldUpdateText = true
    }, 500)

    await axios.put(url, file, {
      headers: {
        "Content-Type": file.type
      },
      onUploadProgress: (progressEvent) => {
        let percentCompleted
        if (progressEvent.total) {
          percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        } else {
          percentCompleted = 0
        }

        if (button && textContainer && shouldUpdateText) {
          textContainer.innerHTML = `${percentCompleted}%`
          button.classList.add("pointer-events-none", "opacity-50")
          button.classList.remove("btn--loading")
        }
      }
    })
      .then(() => {
        if (textContainer) {
          clearTimeout(timeoutId)
        }
        if (button) {
          button.classList.remove("pointer-events-none", "opacity-50")
          button.classList.add("btn--loading")
        }
      })
      .catch(() => {
        event.preventDefault()
        clearTimeout(timeoutId)
        if (button && textContainer) {
          button.classList.remove("pointer-events-none", "opacity-50")
          button.classList.add("btn--loading")
          textContainer.innerHTML = originalText
        }
        return false
      })

    const hiddenInput = form.querySelector("input[name=installer]") as HTMLInputElement | null
    if (hiddenInput) {
      hiddenInput.value = partialKey
    }

    input.setAttribute("disabled", "disabled")

    submit(form)
    return true
  } catch (err) {
    event.preventDefault()
    handleError(form)
    return false
  }

}

const assetVersionFileUploader: AssetVersionFileUploader = {
  uploadFile: uploadFile
}

export default assetVersionFileUploader
