import * as semver from "semver"

window.validateSemverString = (value: string) => {
  return !!semver.validRange(value)
}

window.validateSemverInput = (input: HTMLInputElement) => {
  const value = input.value
  const isValid = window.validateSemverString(value)
  input.closest(".input__wrap")?.classList.toggle("input__wrap--error", !isValid)
}
