import Litepicker from "litepicker"
import htmx from "htmx.org"

export const initAllDatepickers = () => {
  initDatepickers()
  initFilterDatepickers()
  stopLitepickersPropagation()
  removeOldPickers()
}

const initDatepickers = () => {
  const datepickers = document.querySelectorAll("[data-datepicker]")
  for (let i = 0; i < datepickers.length; i++) {
    const datepicker = datepickers[i] as HTMLInputElement
    const initialized = datepicker.getAttribute("data-initialized")

    if (initialized === null) {
      const single = datepicker.getAttribute("data-date-single")
      const maxDateCurrent = datepicker.getAttribute("data-max-date-current")

      new Litepicker({
        element: datepicker,
        singleMode: single !== null,
        dropdowns: { "minYear": 2023, "maxYear": maxDateCurrent === "" ? null : 2250, "months": true, "years": true },
        setup: (picker) => {
          picker.on("render", (ui) => {
            ui.setAttribute("data-picker", "normal")
          })
        }
      })

      datepicker.setAttribute("data-initialized", "")
    }
  }
}

const initFilterDatepickers = () => {
  const datepickers = document.querySelectorAll("[data-datepicker-filter]")
  for (let i = 0; i < datepickers.length; i++) {
    const datepicker = datepickers[i] as HTMLInputElement
    const initialized = datepicker.getAttribute("data-initialized")

    if (initialized === null) {
      const form = datepicker.form as HTMLFormElement
      const single = datepicker.getAttribute("data-date-single")
      const maxDateCurrent = datepicker.getAttribute("data-max-date-current")

      new Litepicker({
        element: datepicker,
        singleMode: single !== null,
        dropdowns: { "minYear": 2023, "maxYear": maxDateCurrent === "" ? null : 2250, "months": true, "years": true },
        setup: (picker) => {
          picker.on("selected", () => {
            datepicker.value = encodeURIComponent(datepicker.value)
            htmx.trigger(form, "submit", {})
          })

          picker.on("render", (ui) => {
            ui.setAttribute("data-picker", "filter")
          })
        }
      })
      datepicker.setAttribute("data-initialized", "")

      initPeriodFilterListener(form)
    }
  }
}

const stopLitepickersPropagation = () => {
  const litepickers = document.getElementsByClassName("litepicker")
  for (let i = 0; i < litepickers.length; i++) {
    litepickers[i].addEventListener("click", (event) => {
      event.stopPropagation()
    })
  }
}

const initPeriodFilterListener = (form: HTMLFormElement) => {
  const periodFilterBadge = document.getElementById("period-filter-badge")
  periodFilterBadge?.addEventListener("click", () => {
    const periodFilter = document.querySelector("input[name=periodFilter]") as HTMLInputElement
    if (periodFilter) {
      periodFilter.value = ""
    }
    htmx.trigger(form, "submit", {})
  })
}


const removeOldPickers = () => {
  const datepickers = document.querySelectorAll("[data-datepicker]")
  const datepickersFilter = document.querySelectorAll("[data-datepicker-filter]")

  const oldPickersNormal = document.querySelectorAll("[data-picker='normal']")
  const oldPickersFilter = document.querySelectorAll("[data-picker='filter']")


  if (oldPickersNormal) {
    for (let i = 0; i < oldPickersNormal.length - datepickers.length; ++i) {
      oldPickersNormal[i].remove()
    }
  }

  if (oldPickersFilter) {
    for (let i = 0; i < oldPickersFilter.length - datepickersFilter.length; ++i) {
      oldPickersFilter[i].remove()
    }
  }
}

window.hideLitepickers = () => {
  const litepickers = document.querySelectorAll(".litepicker")
  if (litepickers.length) {
    litepickers.forEach(e => {
      (e as HTMLElement).style.display = "none"
    })
  }
}
